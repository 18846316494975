<template>
  <div>
    <el-submenu :index="menuObj.code" :popper-append-to-body="false">
      <template slot="title">{{ menuObj.menuName }}</template>
      <template v-for="(item, index) in menuObj.children">
        <template v-if="item.isShow">
          <template v-if="item.children && item.children.length > 0">
            <HorizontalMenuEleItem :menuObj="item"></HorizontalMenuEleItem>
          </template>
          <template v-else>
            <el-menu-item
              v-if="item.linkTo || item.href"
              :index="item.code"
              :key="item.code"
              @click.native="jump(item)"
              >{{ item.menuName }}</el-menu-item
            >
          </template>
        </template>
      </template>
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: 'HorizontalMenuEleItem',
  components: {},
  props: {
    menuObj: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  methods: {
    jump(item) {
      var path = item.linkTo ? item.linkTo : item.href
      this.$router.push(path)
    },
  },
}
</script>
